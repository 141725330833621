import { OnDestroy, OnInit, Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
    ACTION_DISABLE,
    ACTION_ENABLE,
    APPROVALS_REQUESTS_COLLECTION,
    COMPANIES_COLLECTION,
    OP_IN,
    STATUS_ACTIVE,
    STATUS_PENDENT,
} from '../../app.constants';
import { FilterModel } from '../../shared/models/filter.model';
import { FunctionOptions } from '../domain/function-options';
import { AppInjector } from '../util/app.injector';
import { BaseModelComponent } from './base-model.component';

@Component({
    template: ''
})
export abstract class BaseListComponent
    extends BaseModelComponent
    implements OnInit, OnDestroy {
    /* Use subscription to control requests on component destruction */
    protected subscriptions = new Subscription();

    protected firestore: AngularFirestore = AppInjector.get(AngularFirestore);
    protected listItemsSubscription = new Subscription();
    private deleteItemsSubscription = new Subscription();

    public items: any;
    public temps: any;
    public itemsSelected: any[] = [];

    protected removeId: any;
    public pendentApproval: any;
    public loadingIndicator: boolean;

    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.listItems();
    }

    /**
     * Destroy all subscription to avoid duplications.
     */
    ngOnDestroy(): void {
        this.listItemsSubscription.unsubscribe();
        this.deleteItemsSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    initSearch(): void {
        this.advancedSearch();
    }

    protected advancedSearch(): void {
    }

    getCustomListItems(): Observable<any> {
        return null;
    }

    /**
     * List all items from collection according the configured filter.
     */
    listItems(isStart = true, page = 0): void {
        this.loadingIndicator = true;
        this.spinner.show('table-spinner');

        if (this.listItemsSubscription) {
            this.listItemsSubscription.unsubscribe();
            this.listItemsSubscription = new Subscription();
        }

        let getItemsObs =
            this.getCustomListItems() ||
            this.service.search(
                this.getCollectionURL(),
                this.getFilter(),
                this.getFunctionOptionsToList()
            );

        // Get date from auditable properties
        // This transformation is used to simplify the use of customizers in sorting columns
        getItemsObs = getItemsObs.pipe(
            takeUntil(this.authFirebaseService.loggedOut$),
            map((items: Array<any>) => {
                // Sometimes when the user logout this param `items` can be another value different from an array.
                if (!Array.isArray(items)) {
                    return [];
                }
                return items?.map((item) => {
                    if (item.createdAt && item.createdAt['toDate']) {
                        item.createdAt = item.createdAt.toDate();
                    }
                    if (item.lastModifiedAt && item.lastModifiedAt['toDate']) {
                        item.lastModifiedAt = item.lastModifiedAt.toDate();
                    }
                    return item;
                }).filter((item) => this.userService.loggedUser.isDemo || !item.isDemo);
            }),
            tap(() => {
                this.onSelectItem({selected: []});
            }),
        );

        this.listItemsSubscription.add(getItemsObs.subscribe({
            next: (result) => {
                this.setItems(result);
                this.postResult();
                this.spinner.hide('table-spinner');
                this.loadingIndicator = false;
            },
            error: (error) => {
                const message = error.error
                    ? error.error.message
                    : error.message;
                if (message) {
                    if (error.code === 'failed-precondition') {
                        this.notification.error(
                            'messages.error-not-found-index'
                        );
                    } else {
                        this.notification.error(message);
                    }
                    this.fireLogginService.sendErrorLog(
                        `An error occurred while quering items in [${ this.getCollectionURL() }] collection, details: ${ message }`
                    );
                } else {
                    this.notification.error(
                        `An error occurred while quering items.`
                    );
                    this.fireLogginService.sendErrorLog(
                        `An error occurred while quering items in [${ this.getCollectionURL() }] collection, details: ${ JSON.stringify(error) }`
                    );
                }
                this.loadingIndicator = false;
                this.spinner.hide('table-spinner');
            }
        }));
    }

    protected setItems(result) {
        this.items = result;
        this.temps = [ ...result ];
        this.setPendentApprovals(this.items, this.getApprovalRequestId());
    }

    protected postResult(): void {
    }

    async setPendentApprovals(
        items: any[] = [],
        approvalRequestId: string,
        alreadyFetchedApprovalRequest?: any
    ) {
        if (alreadyFetchedApprovalRequest) {
            this.setPendentApprovalChangesToItem(items, {
                id: approvalRequestId,
                ...alreadyFetchedApprovalRequest,
            });
        } else if (approvalRequestId) {
            this.subscriptions.add(
                this.getApprovalRequestById(approvalRequestId).subscribe(
                    (approvalRequest: any) => {
                        this.setPendentApprovalChangesToItem(items, {
                            id: approvalRequestId,
                            ...approvalRequest,
                        });
                    }
                )
            );
        }
    }

    /**
     * Navigate to form
     */
    add(): void {
        this.goToAdd();
    }

    /**
     * Navigate to form with ID
     */
    edit(id): void {
        this.goToEdit(id);
    }

    view(id): void {
        this.goToView(id);
    }

    /**
     * Disables/Enable an item changing the value of <code>status</code> to INACTIVE
     * @param item The item to be disabled/enabled
     */
    changeStatus(item): void {
        const action =
            item.status === STATUS_ACTIVE ? ACTION_DISABLE : ACTION_ENABLE;
        this.removeId = item.id;
        this.spinner.show();
        const functionName =
            this.getRemoveFunctionName() || this.getCollectionURL();
            this.deleteItemsSubscription.add(
                this.service
                    .remove(
                        functionName,
                        this.removeId,
                        this.getFunctionOptionsToRemove()
                    )
                    .subscribe({
                        next: async () => {
                            this.postDelete();
                            if (action === ACTION_ENABLE) {
                                this.notification.enableSuccess();
                            }
                            if (action === ACTION_DISABLE) {
                                this.notification.disableSuccess();
                            }
                            this.spinner.hide();
                        },
                        error: (error) => {
                            this.spinner.hide();
                            const message = error.error
                                ? error.error.message
                                : error.message;
                            if (message) {
                                this.notification.error(message);
                                this.fireLogginService.sendErrorLog(
                                    `An error occurred while updating status with function [${functionName}], details: ${message}`
                                );
                            } else {
                                this.notification.error(
                                    `An error occurred while updating status.`
                                );
                                this.fireLogginService.sendErrorLog(
                                    `An error occurred while updating status with function [${functionName}], details: ${JSON.stringify(
                                        error
                                    )}`
                                );
                            }
                        },
                    })
            );
    }

    hasAuthorityToAdd() {
        return (
            this.userService.hasAnyAuthority(
                this.getPermissionToAddNewItem()
            ) && !this.userService.isFinsteinHelper()
        );
    }

    getPermissionToAddNewItem() {
        return null;
    }

    getFunctionOptionsToList(): FunctionOptions {
        return null;
    }

    protected getRemoveFunctionName() {
        return null;
    }

    protected getApprovalRequestId(): string {
        return null;
    }

    getFunctionOptionsToRemove(): FunctionOptions {
        return null;
    }

    protected postDelete(): void {
    }

    getFilter(): FilterModel {
        const filter = new FilterModel();
        filter.clauses = [
            {
                fieldPath: 'status',
                opStr: OP_IN,
                value: [ STATUS_ACTIVE, STATUS_PENDENT ],
            },
        ];
        filter.column = 'createdAt';
        filter.sort = 'asc';
        return filter;
    }

    getApprovalRequestById(approvalRequestId: string) {
        return this.service.getById(
            approvalRequestId,
            `${ COMPANIES_COLLECTION }/${ this.userService.loggedUser.companyId }/${ APPROVALS_REQUESTS_COLLECTION }`
        );
    }

    get listIsEmpty(): boolean {
        return this.items.length <= 0;
    }

    private goToAdd(): void {
        this.navigate([ this.getRouterURL(), 'form' ]);
    }

    private goToEdit(id = null): void {
        this.navigate([ this.getRouterURL(), 'form', id ? id : '' ]);
    }

    private goToView(id): void {
        this.navigate([ this.getRouterURL(), 'view', id ]);
    }

    private setPendentApprovalChangesToItem(items: any, approvalRequest: any) {
        for (const item of items) {
            if (approvalRequest && approvalRequest.changes) {
                const allChanges = Array.isArray(approvalRequest.changes)
                    ? approvalRequest.changes
                    : [ approvalRequest.changes ];
                const changeFound = allChanges.find((change) => {
                    const ref = this.firestore.doc(change.reference).ref;
                    return ref.id === item.id;
                });
                if (changeFound) {
                    item.pendentApproval = {
                        id: approvalRequest.id,
                        action: changeFound.action,
                        historyRef: changeFound.historyRef,
                        requestedBy: changeFound.requestedBy,
                        reference: changeFound.reference,
                        data: changeFound.data,
                    };
                } else {
                    item.pendentApproval = null;
                }
            } else {
                item.pendentApproval = null;
            }
        }
    }

    onSelectItem({selected}) {
        this.itemsSelected = [];
        this.itemsSelected.push(...selected);
    }
}
